import Repository from "./Repository";

const resource = "/articles";
const dailyResource = "/daily_article";

export default {
    get() {
        return Repository.get(`${resource}/`); //Not usable - too many entries. Remove?
    },
    getFullArticlesByProject(projectId) {
        return Repository.get(`${resource}/project_full/?project=${projectId}`);
    },
    getArticle(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    getByProject(projectId) {
        return Repository.get(`${resource}/?project=${projectId}`);
    },
    getTodaysArticle() {
        return Repository.get(`${dailyResource}/`);
    },
    getByDate(date) {
        return Repository.get(`${dailyResource}/?date=${date}`);
    },
    getByMonth(year, month) {
        return Repository.get(`${resource}/calendar/?year=${year}&month=${month}`)
    },
    getFinalsByProject(projectId) {
        return Repository.get(`${resource}/?project=${projectId}&date_set=1`);
    },
    getByProjectWithoutDate(projectId) {
        return Repository.get(`${resource}/?project=${projectId}&no_date=1`);
    },
    getUnassignedArticles() {
        return Repository.get(`${resource}/?no_project=1&archived=0`);
    },
    getArticlesBySearchTerm(term) {
        return Repository.get(`${resource}/?search=${encodeURI(term)}`);
    },
    getArchivedArticles() {
        return Repository.get(`${resource}/?archived=1`);
    },
    add(title, text, votd, votd_reference, votd_bible_translation, topic = false, content_topic = false, question = false, hint = false, event_day_topic = false, event_day = false, recommended_bible_reference = false, learning_verse = false, learning_verse_ref = false, votd_reference_overwrite = false, votd_is_excerpt = false, writer = false, comment_author = false, project = false, assigned_date = false, reused_article = false, article_planning = false, status = false, used_song_text = false, song_text = false) {
        let payload = { 'title': title, 'text': text, 'votd': votd, 'votd_reference': votd_reference, 'votd_bible_translation': votd_bible_translation };
        if (topic) payload.topic = topic;
        if (content_topic) payload.content_topic = content_topic;
        if (question) payload.question = question;
        if (hint) payload.hint = hint;
        if (event_day_topic) payload.event_day_topic = event_day_topic;
        if (event_day) payload.event_day = event_day;
        if (recommended_bible_reference) payload.recommended_bible_reference = recommended_bible_reference;
        if (learning_verse) payload.learning_verse = learning_verse;
        if (learning_verse_ref) payload.learning_verse_ref = learning_verse_ref;
        if (votd_reference_overwrite) payload.votd_reference_overwrite = votd_reference_overwrite;
        if (votd_is_excerpt) payload.votd_is_excerpt = votd_is_excerpt;
        if (writer) payload.writer = writer;
        if (comment_author) payload.comment_author = comment_author;
        if (project) payload.project = project;
        if (assigned_date) payload.assigned_date = assigned_date;
        if (reused_article) payload.reused_article = reused_article
        if (article_planning) payload.article_planning = article_planning
        if (status) payload.status = status
        if (used_song_text) payload.used_song_text = used_song_text;
        if (song_text) payload.song_text = song_text;
        return Repository.post(`${resource}/`, payload);
    },
    update(id, title, text, votd, votd_reference, votd_bible_translation, topic = false, content_topic = false, question = false, hint = false, event_day_topic = false, event_day = false, recommended_bible_reference = false, learning_verse = false, learning_verse_ref = false, votd_reference_overwrite = false, votd_is_excerpt = false, status = false, writer = false, comment_author = false, used_song_text = false, song_text = false) {
        let payload = { 'title': title, 'text': text, 'votd': votd, 'votd_reference': votd_reference, 'votd_bible_translation': votd_bible_translation };
        if (topic !== false) payload.topic = topic;
        if (content_topic !== false) payload.content_topic = content_topic;
        if (question !== false) payload.question = question;
        if (hint !== false) payload.hint = hint;
        if (event_day_topic !== false) payload.event_day_topic = event_day_topic;
        if (event_day !== false) payload.event_day = event_day;
        if (recommended_bible_reference) payload.recommended_bible_reference = recommended_bible_reference;
        if (learning_verse) payload.learning_verse = learning_verse;
        if (learning_verse_ref) payload.learning_verse_ref = learning_verse_ref;
        if (votd_reference_overwrite !== false) payload.votd_reference_overwrite = votd_reference_overwrite;
        if (status !== false) payload.status = status;
        if (writer) payload.writer = writer;
        if (comment_author) payload.comment_author = comment_author;
        if (used_song_text) payload.used_song_text = used_song_text;
        if (song_text) payload.song_text = song_text;
        payload.votd_is_excerpt = votd_is_excerpt;
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
    setProject(id, project_id) {
        let payload = { 'project': project_id };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    getStatusOptions() {
        return Repository.get(`/articles/status/`);
    },
    setStatus(id, status) {
        let payload = { 'status': status };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    setAssignedDate(id, assigned_date) {
        let payload = { 'assigned_date': assigned_date };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    setRecommendedBibleReference(id, recommended_bible_reference) {
        let payload = { 'recommended_bible_reference': recommended_bible_reference };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    getHistory(id) {
        return Repository.get(`${resource}/${id}/history/`);
    },
    getHistoryPrevState(id, fieldName, date) {
        let params = { 'field': fieldName, 'date': date };
        return Repository.get(`${resource}/${id}/history_prev_state/`, { params: params });
    },
    getComments(id) {
        return Repository.get(`${resource}/${id}/comments/`);
    },
    addComment(article_id, text) {
        let payload = { 'article': article_id, 'text': text };
        return Repository.post(`/comments/`, payload);
    },
    pinComment(id) {
        return Repository.get(`/comments/${id}/pin/`)
    },
    unpinComment(id) {
        return Repository.get(`/comments/${id}/unpin/`)
    },
    getChecks(id) {
        return Repository.get(`${resource}/${id}/checks/`);
    },
    getCheckOptions() {
        return Repository.get(`/article_checks/checks/`);
    },
    addCheck(article_id, check_type) {
        let payload = { 'check_type': check_type, 'article': article_id }
        return Repository.post(`/article_checks/`, payload);
    },
    getStats() {
        return Repository.get(`${resource}/stats/`);
    },
    sendMailToAuthor(id, message) {
        let payload = { 'message': message };
        return Repository.post(`${resource}/${id}/send_mail/`, payload);
    },
    archiveArticles(articleIDs) {
        let payload = { 'articles': articleIDs };
        return Repository.post(`${resource}/archive/`, payload);
    },
    unArchiveArticles(articleIDs) {
        let payload = { 'articles': articleIDs };
        return Repository.post(`${resource}/unarchive/`, payload);
    }
}
